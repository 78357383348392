import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import analytics from '@analytics';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { Button, TextInput, Tooltip, Typography } from '@leaf/ui';
import {
  useCurrentInvestorUserQuery,
  useCurrentInvestorUserLazyQuery,
  useVerifyMultipleHoldingsAuMutation,
} from '@/apollo/generated';
import { useAlert } from '@/contexts/alert-context';
import { groupedCountries } from '@/utils/countries';

interface FormData {
  holdings: {
    country: string;
    id?: string;
    partialHin: string;
    postcode: string;
  }[];
}

const hinTooltipContent = `
A HIN starts with the letter "X" followed by 10 numbers.
You can find your HIN on the top right hand corner of one of your CHESS holding statements.
An SRN starts with the letter "I" and can be found on letters sent to you by the company you own shares in.
`;

interface Props {
  onClose(): void;
  setStep: Dispatch<SetStateAction<'verify' | 'complete'>>;
}

const VerifyHoldingsLiteVerify: React.ComponentType<Props> = ({
  onClose,
  setStep,
}) => {
  useEffect(() => {
    analytics.track('verify_holdings_modal_viewed');
  }, []);

  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);

  const { data } = useCurrentInvestorUserQuery();
  const [currentInvestorUserQuery] = useCurrentInvestorUserLazyQuery();

  const [verifyMultipleHoldingsAu] = useVerifyMultipleHoldingsAuMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['CurrentInvestorUser', 'MediaComments'],
  });

  const defaultValues = useMemo(() => {
    return {
      holdings:
        data?.currentInvestorUser?.shareholderInformations.map((holding) => ({
          country: holding.country ?? 'Australia',
          id: holding.id,
          partialHin: holding.partialHin ?? '',
          postcode: holding.postcode ?? '',
        })) ?? [],
    };
  }, [data?.currentInvestorUser]);

  const { formatAndShowError, showAlert } = useAlert();

  const { control, formState, handleSubmit, register, reset, setError, watch } =
    useForm<FormData>({
      defaultValues,
      resolver: yupResolver(
        yup.object().shape({
          holdings: yup.array().of(
            yup.object().shape({
              country: yup.string().trim().required('Required.'),
              partialHin: yup.string().trim().required('Required.'),
              postcode: yup.string().when('country', {
                is: 'Australia',
                otherwise: () => yup.string(),
                then: () =>
                  yup
                    .string()
                    .trim()
                    .matches(
                      /^[0-9]{4}$/,
                      'Post code should be a 4-digit number.'
                    ),
              }),
            })
          ),
        })
      ),
    });

  const {
    append,
    fields: holdings,
    remove,
  } = useFieldArray({
    control,
    name: 'holdings',
  });

  useEffect(() => {
    reset({ ...defaultValues });
    setIsShowErrorMessage(false);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    setIsShowErrorMessage(false);
    analytics.track('verify_holdings_modal_form_submitted');

    verifyMultipleHoldingsAu({
      variables: {
        holdings: data.holdings.map((holding) => ({
          country: holding.country,
          id: holding.id,
          partialHin: holding.partialHin.slice(holding.partialHin.length - 4),
          postcode:
            holding.country === 'Australia' ? holding.postcode : undefined,
        })),
      },
    })
      .then(async () => {
        await currentInvestorUserQuery().then(async ({ data }) => {
          if (data?.currentInvestorUser?.isHoldingVerified) {
            setIsShowErrorMessage(false);
            analytics.track('verify_holdings_modal_verified');
            return setStep('complete');
          }
          analytics.track('verify_holdings_modal_not_verified');
          setIsShowErrorMessage(true);
          showAlert({
            description: 'We were unable to verify your holdings.',
            variant: 'error',
          });
        });
      })
      .catch(formatAndShowError);
  });

  useEffect(() => {
    if (isShowErrorMessage) {
      holdings.forEach((_, index) => {
        setError(`holdings.${index}.partialHin`, {
          message: 'Enter the last 4 digits of your HIN/SRN',
          type: 'custom',
        });
        setError(`holdings.${index}.postcode`, {
          message: 'No matching postcode',
          type: 'custom',
        });
      });
    }
  }, [isShowErrorMessage, setError, holdings]);

  if (holdings.length === 0) {
    append({ country: 'Australia', partialHin: '', postcode: '' });
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="space-y-6">
        {holdings.map((holding, index) => (
          <div key={holding.id} className="space-y-3">
            <div>
              <div className="mb-1 flex items-center justify-between space-x-2">
                <div className="flex items-center gap-2">
                  <label htmlFor={`holdings[${index}].partialHin`}>
                    <Typography component="span" variant="button">
                      HIN/SRN
                    </Typography>
                  </label>
                  <Tooltip
                    hover
                    buttonClassName="flex items-center"
                    content={hinTooltipContent}
                  />
                </div>
              </div>
              <Controller
                control={control}
                name={`holdings.${index}.partialHin`}
                render={({ field }) => (
                  <InputMask
                    mask="\*\*\*\*\*\*\*9999"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    <TextInput
                      className="mb-2"
                      error={
                        !!formState.errors.holdings?.[index]?.partialHin
                          ?.message
                      }
                      helperText={
                        formState.errors.holdings?.[index]?.partialHin?.message
                      }
                      id={`hin-${index}`}
                      placeholder="X******1234"
                      tooltipContent={hinTooltipContent}
                    />
                  </InputMask>
                )}
              />
              {!formState.errors.holdings?.[index]?.partialHin?.message && (
                <Typography className="text-hubs-secondary" component="span">
                  Please enter the last 4 digits of your HIN/SRN
                </Typography>
              )}
            </div>
            <div>
              <label
                className="mb-1 block"
                htmlFor={`holdings.${index}.country`}
              >
                <Typography component="span" variant="button">
                  Country
                </Typography>
              </label>
              <select
                {...register(`holdings.${index}.country`)}
                className="input"
                id={`country-${index}`}
              >
                {groupedCountries.map((country) => (
                  <option key={country.abbr} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={
                watch(`holdings.${index}.country`) === 'Australia'
                  ? 'block'
                  : 'hidden'
              }
            >
              <TextInput
                {...register(`holdings.${index}.postcode`)}
                autoComplete="postal-code"
                error={!!formState.errors.holdings?.[index]?.postcode?.message}
                helperText={
                  formState.errors.holdings?.[index]?.postcode?.message
                }
                id={`postcode-${index}`}
                label="Postcode"
                placeholder="3000"
              />
            </div>
            {index !== holdings.length - 1 && (
              <div className="pt-3">
                <hr />
              </div>
            )}
            {(index === holdings.length - 1 || index !== 0) && (
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                {index === holdings.length - 1 && (
                  <Button
                    className="w-full pl-0 sm:w-auto"
                    disabled={formState.isSubmitting}
                    variant="tertiary"
                    onClick={() =>
                      append({
                        country: 'Australia',
                        partialHin: '',
                        postcode: '',
                      })
                    }
                  >
                    {'+ '}
                    <span className="underline">Add another account</span>
                  </Button>
                )}
                {index !== 0 && (
                  <Button
                    className="w-full pr-0 underline sm:w-auto"
                    disabled={formState.isSubmitting}
                    variant="tertiary"
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            )}
          </div>
        ))}
        {isShowErrorMessage && (
          <Typography className="text-status-red" variant="body-regular">
            Your holding information could not be verified. Re-enter your
            information and try again.
          </Typography>
        )}
      </div>
      <div className="mt-8 flex-row-reverse gap-4 md:flex">
        <Button
          fullWidth
          disabled={
            formState.isSubmitting ||
            holdings.some(
              (_holding, index) =>
                !watch(`holdings.${index}.partialHin`) ||
                !watch(`holdings.${index}.country`) ||
                (watch(`holdings.${index}.country`) === 'Australia' &&
                  !watch(`holdings.${index}.postcode`))
            )
          }
          type="submit"
        >
          Verify
        </Button>
        <Button
          fullWidth
          disabled={formState.isSubmitting}
          variant="tertiary"
          onClick={() => {
            onClose();
            setIsShowErrorMessage(false);
          }}
        >
          Back
        </Button>
      </div>
    </form>
  );
};

export default VerifyHoldingsLiteVerify;
