import { useCallback, useEffect } from 'react';
import analytics from '@analytics';
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          cancel: () => void;
          initializeIntermediate: (config: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
          prompt: (
            callback?: (notification: GoogleNotification) => void
          ) => void;
          renderButton: (
            element: HTMLElement,
            options: { size: string; theme: string }
          ) => void;
          revoke: (hint: string, callback: () => void) => void;
        };
      };
    };
    handleCredential?: (response: { credential: string }) => void;
  }
}

interface GoogleOneTapProps {
  clientId: string;
  enabled: boolean;
  onError?: (error: Error) => void;
  onGoogleOneTapLoading: () => void;
}

interface GoogleNotification {
  getMomentType: () => string;
  getNotDisplayedReason: () => string;
  isNotDisplayed: () => boolean;
}

const GoogleOneTap: React.FC<GoogleOneTapProps> = ({
  clientId,
  enabled,
  onError,
  onGoogleOneTapLoading,
}) => {
  const handleCredential = useCallback(
    async (credential: string) => {
      analytics.track('google_one_tap_clicked');
      const redirectUrl = window.location.pathname;
      try {
        onGoogleOneTapLoading();
        await fetch(`/api/hermes/auth/google/one-tap`, {
          body: JSON.stringify({
            credential: credential,
            redirect_url: redirectUrl,
            referer: window.location.origin,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          redirect: 'follow',
        }).then((res) => {
          if (res.ok) {
            window.location.assign(redirectUrl);
          }
        });
      } catch (error) {
        onError?.(error as Error);
      }
    },
    [onError, onGoogleOneTapLoading]
  );

  useEffect(() => {
    // Handle messages from the iframe
    const handleMessage = (event: MessageEvent) => {
      const data = event.data;
      if (data.type === 'GOOGLE_ONE_TAP_CREDENTIAL') {
        handleCredential(data.credential);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleCredential]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    // Load the intermediate iframe script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/intermediate';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      // Set the iframe URL including the current domain as a parameter
      const currentDomain = encodeURIComponent(window.location.origin);

      window.google.accounts.id.initializeIntermediate({
        allow: 'identity-credentials-get',
        src: `${process.env.NEXT_PUBLIC_GOOGLE_ONE_TAP_DOMAIN}/auth/google-one-tap?domain=${currentDomain}`,
      });
    };

    document.body.appendChild(script);
    return () => {
      // Cleanup
      delete window.handleCredential;
      document
        .querySelector(
          `script[src="https://accounts.google.com/gsi/intermediate"]`
        )
        ?.remove();
      document.getElementById('g_id_intermediate_iframe')?.remove();
    };
  }, [handleCredential, enabled]);

  return null;
};

export default GoogleOneTap;
