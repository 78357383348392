import React, {
  ComponentType,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { NextSeoProps } from 'next-seo';
import { CurrentCompanyQuery } from '@/apollo/generated';
import HeadTag from '@/components/layouts/head-tag';
import RootLayout from '@/components/layouts/root';
import WebsiteLayout from '@/components/website/layout/website-layout';
import Website404 from '@/components/website/page/404';

interface WebsiteContext {
  currentWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['publishedWebsite'];
  isEmailPopupOpen: boolean;
  isPreviewMode: boolean;
  isWebinarBannerShown: boolean;
  renderContentInLayout: (
    content: React.ReactNode,
    hasContent?: boolean,
    LayoutWithWebsite?: React.FC,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FallbackLayout?: ComponentType<PropsWithChildren<any>>,
    options?: RenderOptions
  ) => React.JSX.Element | null;
  setIsEmailPopupOpen: Dispatch<SetStateAction<boolean>>;
}

const WebsiteContext = createContext<WebsiteContext>({
  currentWebsite: null,
  isEmailPopupOpen: false,
  isPreviewMode: false,
  isWebinarBannerShown: false,
  renderContentInLayout: (_content) => null,
  setIsEmailPopupOpen: () => null,
});

interface RenderOptions {
  overrideSeo?: NextSeoProps;
}

export const WebsiteProvider: React.ComponentType<{
  children?: React.ReactNode;
  isPreviewMode: boolean;
  liveWebinar: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['liveWebinar'];
  publishedWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['publishedWebsite'];
  recentScheduledWebinar: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['recentScheduledWebinar'];
  unpublishedWebsite: NonNullable<
    CurrentCompanyQuery['currentCompany']
  >['unpublishedWebsite'];
}> = ({
  children,
  isPreviewMode,
  liveWebinar,
  publishedWebsite,
  recentScheduledWebinar,
  unpublishedWebsite,
}) => {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const currentWebsite = useMemo(() => {
    if (isPreviewMode && unpublishedWebsite) {
      return unpublishedWebsite;
    }
    if (publishedWebsite) {
      return publishedWebsite;
    }
    return null;
  }, [isPreviewMode, unpublishedWebsite, publishedWebsite]);

  const isWebinarBannerShown = Boolean(
    publishedWebsite?.showWebinarBanner &&
      (recentScheduledWebinar || liveWebinar)
  );

  const renderContentInLayout = (
    content: React.ReactNode,
    hasContent = true,
    LayoutWithWebsite = WebsiteLayout,
    FallbackLayout = RootLayout,
    options?: RenderOptions
  ) => {
    const Layout = currentWebsite ? LayoutWithWebsite : FallbackLayout;
    if (!hasContent) {
      return (
        <Layout>
          <Website404 />
        </Layout>
      );
    }

    return (
      <Layout>
        <HeadTag overrideSeo={options?.overrideSeo} />
        {content}
      </Layout>
    );
  };

  return (
    <WebsiteContext.Provider
      value={{
        currentWebsite,
        isEmailPopupOpen,
        isPreviewMode,
        isWebinarBannerShown,
        renderContentInLayout,
        setIsEmailPopupOpen,
      }}
    >
      {children}
    </WebsiteContext.Provider>
  );
};

export const useWebsite = () => useContext(WebsiteContext);
