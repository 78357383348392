import { Fragment, ReactElement, SVGProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Typography, Button } from '@leaf/ui';

interface Props {
  HeaderIcon?: (props: SVGProps<SVGSVGElement>) => ReactElement | null;
  allowOverflow?: boolean;
  autoClose?: boolean;
  bottomModal?: boolean;
  children?: React.ReactNode;
  className?: string;
  headerButtonAction?: () => void;
  headerButtonText?: string;
  headerButtonVariant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-active';
  headerDescriptionText?: string | null;
  headerImage?: React.ReactNode;
  headerText: string | null;
  headerTextAlign?: 'left' | 'center';
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  loading?: boolean;
  onClose(): void;
  open: boolean;
  playerIcon?: React.ReactNode;
  primaryButtonAction?: () => void;
  primaryButtonText?: string;
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
  showTopBorder?: boolean;
  size?: 'sm' | 'md';
}

// TODO: replace all modals into this new generic modal
const GenericModal: React.ComponentType<Props> = ({
  HeaderIcon,
  allowOverflow,
  autoClose = false,
  bottomModal,
  children,
  className,
  headerButtonAction,
  headerButtonText,
  headerButtonVariant,
  headerDescriptionText,
  headerImage,
  headerText,
  headerTextAlign = 'left',
  initialFocus,
  loading,
  onClose,
  open,
  playerIcon,
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  showTopBorder = true,
  size = 'sm',
}) => {
  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog
        className={clsx('fixed inset-0 z-40', className)}
        initialFocus={initialFocus}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-primary-off-black/30" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="translate-y-full"
          enterTo="translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="translate-y-0"
          leaveTo="translate-y-full"
        >
          <div
            className={clsx(
              'flex flex-col items-center sm:p-6',
              bottomModal ? 'h-full' : 'h-screen items-center sm:h-full'
            )}
          >
            <div className="flex-1" />
            <div
              className={clsx(
                'relative w-full rounded-lg border bg-hubs-background shadow-lg',
                bottomModal ? 'h-auto' : 'h-screen',
                allowOverflow ? 'overflow-visible' : 'overflow-y-auto',
                size === 'sm'
                  ? 'max-w-sm  sm:max-w-lg'
                  : 'max-w-lg  sm:max-w-xl'
              )}
            >
              {!autoClose && (
                <>
                  <button
                    className={clsx(
                      'fixed right-2 top-2 z-10',
                      bottomModal ? 'hidden' : 'sm:hidden'
                    )}
                    type="button"
                    onClick={onClose}
                  >
                    <XIcon className="h-8 w-8" />
                  </button>
                  <div
                    className={clsx(
                      'absolute right-0 top-0 z-10 pr-4 pt-4',
                      bottomModal ? 'block' : 'hidden sm:block'
                    )}
                  >
                    <button
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      type="button"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                </>
              )}

              <div className="sm:rounded-lg">
                {showTopBorder ? (
                  <div className="h-2 bg-company-primary" />
                ) : null}
                <div
                  className={clsx('space-y-12 p-6', !showTopBorder && 'pt-12')}
                >
                  <div className="space-y-6">
                    {playerIcon}
                    {headerImage}
                    {HeaderIcon && (
                      <div className="flex h-16 w-16 items-center justify-center rounded-full bg-company-primary">
                        <HeaderIcon className="h-[26px] w-[26px] text-company-primary" />
                      </div>
                    )}
                    <div className="space-y-2">
                      <Typography
                        className={clsx(
                          'text-hubs-primary',
                          headerTextAlign === 'left'
                            ? 'text-left'
                            : 'text-center'
                        )}
                        variant="heading-4"
                      >
                        {headerText}
                      </Typography>
                      {headerDescriptionText ? (
                        <Typography
                          className="text-hubs-secondary"
                          variant="body-regular"
                        >
                          {headerDescriptionText}
                        </Typography>
                      ) : null}
                    </div>
                    {headerButtonAction && headerButtonText && (
                      <Button
                        className="w-full"
                        variant={headerButtonVariant}
                        onClick={headerButtonAction}
                      >
                        {headerButtonText}
                      </Button>
                    )}
                  </div>

                  {children}

                  {(!!primaryButtonText || !!secondaryButtonText) && (
                    <div className="w-full items-center justify-start gap-4 space-y-4 sm:flex sm:sm:flex-row-reverse sm:space-y-0">
                      {primaryButtonText && primaryButtonAction && (
                        <Button
                          className="w-full"
                          disabled={loading}
                          variant="primary"
                          onClick={primaryButtonAction}
                        >
                          {primaryButtonText}
                        </Button>
                      )}

                      {secondaryButtonText && secondaryButtonAction && (
                        <Button
                          className="w-full"
                          disabled={loading}
                          variant="secondary"
                          onClick={secondaryButtonAction}
                        >
                          {secondaryButtonText}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={clsx(
                bottomModal ? 'hidden flex-1 sm:block' : 'flex-1'
              )}
            />
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default GenericModal;
