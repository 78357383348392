import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { Checkbox as UICheckbox } from '@leaf/ui';

interface CheckboxProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  colour?: string;
}

const Checkbox: React.ComponentType<CheckboxProps> = ({ ...props }) => {
  return <UICheckbox {...props} />;
};

export default Checkbox;
