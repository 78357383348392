// CookieBanner.tsx
import React, { useEffect } from 'react';
import analytics from '@analytics';
import { XMarkIcon } from '@heroicons/react-v2/24/solid';
import { useForm } from 'react-hook-form';
import {
  getCookieConsent,
  setCookieConsent,
  CookieConsent,
} from '@leaf/helpers';
import { maybeAddRelNofollow } from '@leaf/helpers';
import { Button, Typography } from '@leaf/ui';
import CustomiseCookies from '@/components/cookie-manager/customise-cookies';
import { useCookieBanner } from '@/contexts/cookie-banner-context';
import { useCurrentCompany } from '@/contexts/current-company-context';
import { useWebsite } from '@/contexts/website-context';

/*
  A banner that appears at the bottom of the screen to inform the user that we use cookies.

  Note that 'cookies' is an outdated term, it's more about user data being used for analytics or marketing.

  There are multiple types of cookies:
    - Necessary (permanently on)
    - Analytics (toggles Mixpanel and our own analytics (see TrackingEventMutation, which stores data in the tracking_investor_hub table))
    - Marketing (we currently don't use marketing cookies but here for future proofing)

  The banner has three buttons:
    - Customise
    - Reject
    - Accept all

  The banner is hidden if the user has already accepted or rejected cookies. This is stored in a cookie called 'cookieConsent' as JSON:

      {
        analytics: true,
        marketing: false
      }

  To set these individually a user can click 'Customise', which opens a form with checkboxes.
*/
const CookieBanner: React.FC = () => {
  const {
    currentCompany: {
      ticker: { marketListingKey },
    },
    isUK,
  } = useCurrentCompany();

  const { currentWebsite, setIsEmailPopupOpen } = useWebsite();

  const { cookieBannerVisible, setCookieBannerVisible } = useCookieBanner();
  const [showCustomiseCookies, setShowCustomiseCookies] = React.useState(false);
  const { control, handleSubmit } = useForm<CookieConsent>({
    defaultValues: { analytics: false },
  });

  useEffect(() => {
    const cookieConsent = getCookieConsent();

    if (cookieConsent === undefined) {
      setCookieBannerVisible(true);
      setIsEmailPopupOpen(false);
    }
  }, [setCookieBannerVisible, setIsEmailPopupOpen]);

  const handleCookieConsent = (consents: CookieConsent) => {
    setCookieConsent(consents);
    setIsEmailPopupOpen(false);
    setCookieBannerVisible(false);
    analytics.loadIfConsented('hermes');
  };

  const onSubmit = (data: CookieConsent) => {
    handleCookieConsent(data);
  };

  if (!cookieBannerVisible) return null;

  return (
    <>
      <div className="fixed bottom-0 z-[999] w-full border-company-primary-text bg-company-primary text-company-primary-text">
        <div
          className="absolute right-4 top-4 z-10 cursor-pointer"
          onClick={() =>
            handleCookieConsent({ analytics: false, marketing: false })
          }
        >
          <XMarkIcon className="size-8 sm:size-6" />
        </div>
        <div className="relative m-0 mx-auto flex h-full max-h-[90vh] max-w-screen-xl flex-col gap-5 overflow-y-auto p-4 sm:flex-row sm:items-end sm:justify-start sm:space-y-0 sm:p-8 md:gap-10">
          {showCustomiseCookies ? (
            <CustomiseCookies
              control={control}
              onCancel={() => setShowCustomiseCookies(false)}
              onSubmit={handleSubmit(onSubmit)}
            />
          ) : (
            <>
              {currentWebsite?.cookieBanner ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentWebsite.cookieBanner,
                  }}
                  className="justify-center font-body text-base font-normal !leading-tight tracking-normal"
                />
              ) : (
                <div className="justify-center">
                  <Typography className="mb-4 !font-normal" variant="heading-4">
                    We value your privacy
                  </Typography>
                  <Typography className="font-body">
                    We use cookies, including those from select third parties,
                    to enhance your experience on our website and to help
                    improve our services as specified in our{' '}
                    <a
                      className="underline"
                      href={
                        isUK
                          ? 'https://investorhub.com/uk-microsite-privacy-policy'
                          : 'https://investorhub.com/au-microsite-privacy-policy'
                      }
                      rel={`noopener noreferrer ${maybeAddRelNofollow(
                        marketListingKey
                      )}`}
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    . By clicking &quot;Accept all&quot;, you consent to our use
                    of cookies.
                  </Typography>
                </div>
              )}

              <div className="flex w-full flex-1 flex-col gap-4 sm:w-auto sm:flex-row md:justify-end">
                <Button
                  className="bg-company-primary text-company-primary-text"
                  onClick={() => setShowCustomiseCookies(true)}
                >
                  Customise
                </Button>
                <Button
                  className="bg-company-accent text-company-accent-text"
                  onClick={() =>
                    handleCookieConsent({ analytics: false, marketing: false })
                  }
                >
                  Reject all
                </Button>
                <Button
                  className="bg-company-accent text-company-accent-text"
                  onClick={() =>
                    handleCookieConsent({ analytics: true, marketing: true })
                  }
                >
                  Accept all
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CookieBanner;
